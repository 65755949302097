'use strict';

app.header = {
	init: function init() {
		this.reloadBaseVh();
		this.scrollEventListResize();
		this.windowSize();
	},
	scrollEventListResize: function scrollEventListResize() {
		var _this = this;

		window.addEventListener('resize', function () {
			_this.reloadBaseVh();
		});
	},
	reloadBaseVh: function reloadBaseVh() {
		var vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', vh + 'px');
	},
	windowSize: function windowSize() {
		if ($(window).width() <= '768') {
			var video = $('.header__video');
			video.html('<source src="./assets/videos/promo-mobile.webm" type="video/webm"></source> <source src="./assets/videos/promo-mobile.ogv" type="video/ogg"></source> <source src="./assets/videos/promo-mobile.mp4" type="video/mp4"></source>');
			video[0].load();
			video[0].play();
		}
	}
};